import React, { useState } from "react";
import "./Header.css";
import { Col, Container, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { IoMdLogIn, IoMdLogOut } from "react-icons/io";
import { FiMessageSquare } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { AUTH_USER_SUCCESS } from "../Helpers/Constant";
import { useAuthContext } from "../context/AuthContext";
import HeaderForPhone from "./HeaderForPhone";

export const Header = () => {
  const { dispatch: AuthDispatch, user } = useAuthContext();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const handleLogout = () => {
    AuthDispatch({ type: AUTH_USER_SUCCESS, payload: {} });
    localStorage.removeItem("__u_o");
  };

  const handelSearch = () => {
    if (searchText) {
      navigate(`/result?search=${searchText}`);
    }
  };

  return (
    <div>
      <div className="header-color d-none d-sm-block">
        <Container>
          <div className="out-source-header d-flex justify-content-between  ">
            <div>
              {/* <ul>
                <li>
                  <Link to='/home'>Ontheway</Link>
                </li>
                <li>
                  <Link to='/q-source'>Home</Link>
                </li>
              </ul> */}
            </div>

            <div>
              <ul>
                <li>
                  <Link to="/" title="Home">
                    Home
                  </Link>
                </li>
                {/* <li>
                  <Link to='/queens'>All-Queens</Link>
                </li> */}
                <li>
                  <Link to="/allservices/allcategory" title="All-Services">
                    All-Services
                  </Link>
                </li>
                <li>
                  <a
                    href="http://ontheway.com.bd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ontheway
                  </a>
                </li>
                {!user.phone ? (
                  <li className="">
                    <Link to="/login" className="d-flex align-items-center">
                      <IoMdLogIn className="fs-5" title="Join" /> Join
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link
                      onClick={handleLogout}
                      to="#"
                      className="d-flex align-items-center"
                    >
                      <IoMdLogOut className="fs-5" title="Logout" /> Logout
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <HeaderForPhone />
      <Container>
        <div className="px-4 py-2">
          <Row className="align-items-center">
            <Col md={3} className="my-md-0 my-2 d-none d-md-block">
              <Link to="/" className="text-decoration-none">
                <div className="d-flex align-items-center">
                  <img
                    loading="lazy"
                    className="w-25 img-fluid"
                    src="/assets/freelancing.png"
                    alt=""
                  />
                  <p
                    style={{ color: "#1c4594", marginTop: "40px" }}
                    className="bold "
                  >
                    Freelancing
                  </p>
                </div>
              </Link>
            </Col>
            <Col md={6} className="mt-3 mt-md-0">
              <div className="d-flex align-items-center search-wrapper-out-sourcing">
                <div className="w-100 ">
                  <input
                    className="w-100 search-box ps-3 "
                    type="text"
                    placeholder="Search service"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && handelSearch();
                    }}
                  />
                </div>

                <div
                  title="Search"
                  onClick={handelSearch}
                  className="out-sourcing-search-btn pointer"
                >
                  <FiSearch className="fs-4" />
                </div>
              </div>
            </Col>
            <Col md={3} className="mt-3 mt-md-0 d-none d-md-block">
              <div className="d-flex gap-4 align-items-center justify-content-center">
                <div className="d-flex gap-4 align-items-center justify-content-center">
                  <div className="pointer">
                    <a
                      href="https://seller.ontheway.com.bd"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        title="seller-app"
                        src="/assets/freelancing.png"
                        alt=""
                        style={{ width: "25px ", objectFit: "contain" }}
                      />
                    </a>
                  </div>
                  <div>
                    {user?.phone && (
                      <Link to="/message">
                        <FiMessageSquare size={30} title="inbox" />
                      </Link>
                    )}
                  </div>
                </div>

                <div>
                  {user.phone && (
                    <Link
                      to="/account"
                      className="d-flex align-items-center justify-content-center text-decoration-none text-dark fw-bold"
                    >
                      <AiOutlineUser
                        className="fs-3 text-dark"
                        title="Buyer Account"
                      />
                      Profile
                    </Link>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
