import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiFillHome, AiOutlineMenu, AiOutlineUser } from "react-icons/ai";
import { IoMdLogIn, IoMdLogOut } from "react-icons/io";
import { FaUserAlt } from "react-icons/fa";
import { useAuthContext } from "../context/AuthContext";
import { AUTH_USER_SUCCESS } from "../Helpers/Constant";

const HeaderForPhone = () => {
  const { dispatch: AuthDispatch, user } = useAuthContext();

  const [showMenu, setShowMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const handleLogout = () => {
    AuthDispatch({ type: AUTH_USER_SUCCESS, payload: {} });
    localStorage.removeItem("__u_o");
  };

  return (
    <>
      {" "}
      <div
        className={showMenu || showUserMenu ? "o-back" : "d-none"}
        onClick={() => {
          setShowMenu(false);
          setShowUserMenu(false);
        }}
      ></div>
      <div className="position-fixed w-100 d-sm-none header-phone-wrapper">
        <ul
          className={`${
            showUserMenu ? "menu-items show" : "hide-menu-items"
          } ps-0 mb-0`}
          onClick={() => setShowMenu(false)}
        >
          {user.phone && (
            <li>
              <Link to="/account">
                <AiOutlineUser className="fs-3 " />
                Profile
              </Link>
            </li>
          )}
          {!user.phone ? (
            <li>
              <Link to="/login">
                <IoMdLogIn className="fs-5" />
                join
              </Link>
            </li>
          ) : (
            <li>
              <Link onClick={handleLogout} to="#">
                <IoMdLogOut className="fs-5" /> logOut
              </Link>
            </li>
          )}
        </ul>
        <ul
          className={`${
            showMenu ? "menu-items show " : "hide-menu-items"
          } ps-0 mb-0`}
          // onClick={() => setShowMenu(false)}
        >
          <li>
            <Link to="/allservices/allcategory">All Services</Link>
          </li>
          <li>
            <Link to="/message">Message</Link>
          </li>
          <li>
            <a
              href="https://seller.ontheway.com.bd/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              Seller app
            </a>
          </li>
        </ul>
        <div className="d-flex justify-content-between py-2 px-5 nav-for-phone">
          <Link to="/" className="text-white">
            <div>
              <AiFillHome
                onClick={() => {
                  setShowMenu(false);
                  setShowUserMenu(false);
                }}
                className="fs-1"
              />
            </div>
          </Link>
          <div>
            <FaUserAlt
              onClick={() => {
                setShowUserMenu(!showUserMenu);
                setShowMenu(false);
              }}
              className={`fs-1 ${showUserMenu && "text-dark"}`}
            />
          </div>

          <div>
            <AiOutlineMenu
              onClick={() => {
                setShowMenu(!showMenu);
                setShowUserMenu(false);
              }}
              className={`fs-1 pointer ${showMenu && "text-dark"} `}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderForPhone;
